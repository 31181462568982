import React from 'react';
import { Grid } from '@mui/material';
import Roadmap from '../components/Roadmap';
class RoadmapPage extends React.Component {

    render() {
        return (
            <Grid container spacing={2}>
            <Grid item xs={12}>
                ROADMAP
                <Roadmap />
                </Grid>
            </Grid>
        );
    }
}

export default RoadmapPage;