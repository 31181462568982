import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
      background: {
        paper: '#000000',
      },
      primary: {
        main: "#1ee97f",
      },
      secondary: {
        main: '#000000',
      },
      error: {
        main: '#ff0000',
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            "&.MuiTypography-body1":{
              color: '#ffffff',
              fontSize: '16px'
            },
            "&.MuiTypography-body2":{
              color: '#1ee97f',
              fontSize: '16px'
            },
            "&.MuiTypography-h5":{
              color: '#ffffff',
              fontSize: '36px'
            },
            "&.MuiTypography-h6":{
              color: '#1ee97f',
              fontSize: '14px'
            }
          }),
        }
      },
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            "&.MuiButton-text":{
              color: '#ffffff',
              fontSize: '14px',
              display: 'inline-block',
              paddingBottom: '2px',
              backgroundImage: 'linear-gradient(#1ee97f 0 0)',
              backgroundPosition: '0 100%',
              backgroundSize: '0% 3px',
              backgroundRepeat: 'no-repeat',
              transition: 'background-size 0.5s, background-position 0s 0.5s, color 0.5s',
              "&:hover":{
                color: '#1ee97f',
                backgroundPosition: '100% 100%',
                backgroundSize: '100% 3px'
              }
            },
            "&.MuiButton-contained":{
              color: '#ffffff',
              borderRadius: '50px',
              "&:hover":{
                color: '#000000',
                backgroundColor: '#ffffff'
              }
            },
          })
        }
      },
      MuiCard: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            backgroundColor: '#0f0f0f',
            borderRadius: '50px'
          })
        }
      }
    }
  });

export default theme;