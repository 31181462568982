import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface FAQElements {
    items: { q: string; a: string; }[];
}

function FAQItem(props: FAQElements) {
    return (
        <div>
            {props.items.map((item, index) => (
                <div key={index}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: '#1ee97f' }}/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                            <Typography  variant="h6">{ item.q }</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                { item.a }
                        </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <hr />
                </div>
            ))}
        </div>
    );
  }

export default FAQItem;