import React from 'react';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { Grid, Card, CardContent, Typography, Stack } from '@mui/material';
import Roadmap from '../components/Roadmap';
import logo2 from './../assets/img/logo/LOGO_2.svg';
import Lootbox from '../components/Lootbox';
import FAQItem from '../components/FAQItem';
import {LootboxImages} from './../helpers/lootboxes';

class HomePage extends React.Component {
    render() {
        const faq_questions = [
            {
                q: "How to get NFT Cheeseverse?" ,
                a: "Cheeseverse with tokens as you all love, we make cool NFT’s that will soon be worth millions of dollars. We are the future of NFT’s.Cheeseverse with tokens as you all love, we make cool NFT’s that will soon be worth millions of dollars. We are the future of NFT’s."
            },
            {
                q: "How to get NFT Cheeseverse?" ,
                a: "Cheeseverse with tokens as you all love, we make cool NFT’s that will soon be worth millions of dollars. We are the future of NFT’s.Cheeseverse with tokens as you all love, we make cool NFT’s that will soon be worth millions of dollars. We are the future of NFT’s."
            }
        ];
        
        return (
            <Grid container spacing={2}>
                <Grid item xs={1}>
                    <img src={logo2} alt="logo2" loading="lazy"/>
                </Grid>
                <Grid item xs={11}>
                </Grid>
                <Grid item xs={8}>
                <Stack spacing={2}>
                    <h1 style={{textAlign: "left", fontWeight: "400"}}>WELCOME TO CHEESEVERSE</h1>
                    <span>Composable NFT collection</span>
                    <Button component={Link} variant="contained" to="about" className="DefaultButton" color="primary">About</Button>
                </Stack>
                </Grid>
                <Grid item xs={4}>
                    <Lootbox rarity="rare" image={LootboxImages.image.rare} />
                </Grid>
            
                <Grid item xs={12}>
                    <Card raised >
                        <CardContent>
                            <Typography variant="h5">
                            WHAT IS CHEESEVERSE?
                            </Typography>
                            <Typography variant="body1">
                            Cheeseverse with tokens as you all love, we make cool NFT’s that will soon be worth millions of dollars. We are the future of NFT’s.Cheeseverse with tokens as you all love, we make cool NFT’s that will soon be worth millions of dollars. We are the future of NFT’s.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            
                <Grid item xs={12}>
                    <h2>FAQ</h2>
                    <hr />
                    <FAQItem items={faq_questions} />
                </Grid>
                <Grid item xs={12}>
                    <h2>Roadmap</h2>
                    <Roadmap />
                </Grid>
                
                <Grid item xs={12}>
                    <h2>CREATE THE FUTURE RIGHT NOW</h2>
                    <Button component={Link} variant="contained" to="join" className="DefaultButton" color="primary">JOIN TO CHEESEVERSE</Button>
                </Grid>
            </Grid>
        );
    }
}

export default HomePage;