import React from 'react';
import { Grid } from '@mui/material';


class NFTPage extends React.Component {
    render() {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    NFT page
                </Grid>
            </Grid>
        );
    }
}

export default NFTPage;