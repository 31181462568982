import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import './assets/css/madeoutersans.css';
import NavigationBar from './components/NavigationBar';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import NFTPage from './pages/NFTPage';
import WikiPage from './pages/WikiPage';
import RoadmapPage from './pages/RoadmapPage';
import JoinPage from './pages/JoinPage';
import Phase1Page from './pages/Phase1Page';

function App() 
{
  
  return (
    <BrowserRouter>
    <div className="App">
      <NavigationBar />
      <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="nft" element={<NFTPage />} />
          <Route path="wiki" element={<WikiPage />} />
          <Route path="roadmap" element={<RoadmapPage />} />
          <Route path="join" element={<JoinPage />} />
          <Route path="phase1" element={<Phase1Page />} />
      </Routes>
    </div>
    </BrowserRouter>
  );
}

export default App;
