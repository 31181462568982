import React from 'react';

class WikiPage extends React.Component {
    render() {
        return (
            <div>
            Wiki page
            </div>
        );
    }
}

export default WikiPage;