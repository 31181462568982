import { Button, Box, AppBar, Toolbar } from '@mui/material';
// import OpenInNew from '@mui/icons-material/OpenInNew';
import logo from './../assets/img/logo/1500x500.png';
import '../assets/css/glitch.css';
import { Link } from "react-router-dom";
function NavigationBar() {
    return (
      <AppBar position="static" color="secondary">
        <Toolbar>
          <Link  to="/">
            <img src={logo} className="glitch" alt="logo" loading="lazy"/>
          </Link>
          <Box sx={{ flexGrow: 1 }} />
          <Button component={Link} variant="text" to="nft" className="DefaultButton" color="primary">NFT</Button>
          <Button component={Link} variant="text" to="wiki" className="DefaultButton" color="primary">Wiki</Button>
          <Button component={Link} variant="text" to="roadmap" className="DefaultButton" color="primary">Road-map</Button>
          <Button component={Link} variant="text" to="phase1" className="DefaultButton" color="primary">Phase 1</Button>
          <Button component={Link} variant="text" to="join" className="DefaultButton" color="primary">Join</Button>
          {/* <Button startIcon={<OpenInNew />} variant="text" href="https://raresama.com/" target="_blank">Raresama</Button> */}
        </Toolbar>
      </AppBar>
    );
  }

export default NavigationBar;