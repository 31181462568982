import { Card, CardMedia, CardContent, Typography } from '@mui/material';
import {LootboxImages} from './../helpers/lootboxes';

//import lootbox_rare from './../assets/img/lootbox/lootbox-rare.jpg';
// import lootbox_limited from './../assets/img/lootbox/lootbox-limited.jpg';
// import lootbox_epic from './../assets/img/lootbox/lootbox-epic.jpg';
// import lootbox_legendary from './../assets/img/lootbox/lootbox-legendary.png';

interface Rarity {
    rarity: string;
    image: string;
}

function capitalizeFirstLetter(word:String) {
    return word.charAt(0).toUpperCase() + word.slice(1);
}

function Lootbox(props:Rarity) {
    return (
        <Card variant="outlined">
        <CardMedia
            component="img"
            height="310"
            image={ props.image }
            alt={`${props.rarity} lootbox`}
            className="lootbox"
            sx={{borderRadius: "50px"}}
        />
        <CardContent>
            <Typography variant="body1">
            Cheesebox
            </Typography>
            <Typography variant="body2">
            { capitalizeFirstLetter(props.rarity) }
            </Typography>
        </CardContent>
    </Card>
    );
  }


export default Lootbox;