import React from 'react';

class AboutPage extends React.Component {
    render() {
        return (
            <div>
            About page
            </div>
        );
    }
}

export default AboutPage;