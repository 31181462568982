import React from 'react';
import { Grid, Button } from '@mui/material';

class JoinPage extends React.Component {
    render() {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                <h1>HOW TO JOIN</h1>
                <span>Follow the steps</span>
                </Grid>
                <Grid item xs={12}>
                    <span>loading</span>
                </Grid>
                <Grid item xs={12}>
                    <span>steps</span>
                </Grid>
                <Grid item xs={6}>
                    <span>#001</span>
                </Grid>
                <Grid item xs={6}>
                    <span>[Image of globe]</span>
                </Grid>
                <Grid item xs={12}>
                    <span>Register</span>
                    <span>Each loot box has a chance of droppin items of different rarity</span>
                </Grid>

                <Grid item xs={6}>
                    <span># 002</span>
                </Grid>
                <Grid item xs={6}>
                    <span></span>
                </Grid>
                <Grid item xs={6}>
                    <span>[image]</span>
                </Grid>
                <Grid item xs={6}>
                    <span>WALLET</span>
                    <span>Each thing has a unique DNA, which allows it to stand out from other metaverses.</span>
                </Grid>
                
                <Grid item xs={4}>
                    <span># 003</span>
                </Grid>
                <Grid item xs={4}>
                    <span>CLAIM NFT</span>
                    <span>Characters are divided into different genders, which allows you to maximally uniqueize the metaverse</span>
                </Grid>
                <Grid item xs={4}>
                    <span>[Image of bee hive]</span>
                </Grid>
                <Grid item xs={12}>
                    <h1>Take your NFT</h1>
                    <Button variant="contained" className="DefaultButton" color="primary">Claim NFT</Button>
                </Grid>
                
            </Grid>
        );
    }
}

export default JoinPage;