import { Stack, Card, CardContent, Typography } from '@mui/material';

function Roadmap() {
    return (
      <div className="Roadmap">
          <Stack direction="row" spacing={2}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                        Q3 2022
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Current
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            stage 1: BIRTH ERA
                        </Typography>
                    </CardContent>
                </Card>
                <Card variant="outlined">
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                        Q4 2022
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            stage 2: GOLD ERA
                        </Typography>
                    </CardContent>
                </Card>
                <Card variant="outlined">
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                        Q3 2023
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        stage 3: BRILLIANT ERA
                        </Typography>
                    </CardContent>
                </Card>
            </Stack>
      </div>
    );
  }

export default Roadmap;