import React from 'react';
import { Grid, Stack } from '@mui/material';
import {LootboxImages} from './../helpers/lootboxes';
import Lootbox from '../components/Lootbox';

class Phase1Page extends React.Component {
    render() {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                <h1>PHASE 1</h1>
                <h1>«BIRTH ERA»</h1>
                Welcome new phase

                About Phase
                LOADING

                LOOTOBOX#EXE

                LOOTBOX TYPES
                All lootbox types cheeseverse

                <Stack direction="row" spacing={2}>
                    <Lootbox rarity="limited" image={LootboxImages.image.limited} />
                    <Lootbox rarity="rare" image={LootboxImages.image.rare} />
                    <Lootbox rarity="epic" image={LootboxImages.image.epic} />
                    <Lootbox rarity="legendary" image={LootboxImages.image.legendary} />
                    
                    

                    
                </Stack>
                </Grid>
            </Grid>
        );
    }
}

export default Phase1Page;